const badge = JSON.parse(localStorage.getItem("badgeNotiflegal")!);
const DocMenuConfig = [
  // {
  //   pages: [
  //     {
  //       heading: "dashboard",
  //       route: "/dashboard",
  //       svgIcon: "/media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },

  {
    pages: [
      {
        heading: "Dashboard",
        route: "/superadmin/dashboard",
        svgIcon: "/media/icons/superadmin/dashboard.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Notifikasi",
        route: "/superadmin/notification",
        svgIcon: "/media/icons/superadmin/Notification.svg",
        fontIcon: "bi-layers",
        notif: badge,
      },
      {
        sectionTitle: "Sekolah",
        svgIcon: "/media/icons/superadmin/client.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Sekolah",
            route: "/superadmin/sekolah",
          },
          {
            heading: "Siswa",
            route: "/superadmin/siswa",
          },
          {
            heading: "History Daftar",
            route: "/superadmin/siswa/history",
          },
        ],
      },

      {
        sectionTitle: "Sales & Marketing",
        svgIcon: "/media/icons/superadmin/implementator.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Sales Area",
            route: "/superadmin/sales/sales-area",
          },
        ],
      },
      {
        sectionTitle: "Legal",
        svgIcon: "/media/icons/superadmin/legal.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Legalitas",
            route: "/superadmin/legal/legalitas",
          },
          {
            heading: "Konfirmasi Legal",
            route: "/superadmin/legal/konfirmasiLegal",
          },
          {
            heading: "Data NPWP",
            route: "/superadmin/legal/data-npwp",
          },
        ],
      },
      {
        sectionTitle: "Finance",
        svgIcon: "/media/icons/superadmin/finance.svg",
        fontIcon: "bi-layers",
        sub: [
          // {
          //   heading: "Finance",
          //   route: "/superadmin/finance",
          // },
          {
            heading: "Invoice",
            route: "/superadmin/invoices",
          },
          {
            heading: "Persetujuan Finance",
            route: "/superadmin/konfirmasi-invoice",
          },
          {
            heading: "Setting Invoice",
            route: "/superadmin/setting-invoices",
          },
          {
            sectionTitle: "Log VA",
            sub: [
              {
                heading: "Log Create VA",
                route: "/superadmin/log-create-va",
              },
              {
                heading: "Log Callback VA",
                route: "/superadmin/log-callback-va",
              },
            ],
          },
        ],
      },
      {
        sectionTitle: "Produk",
        svgIcon: "/media/icons/superadmin/dashboard.svg",
        fontIcon: "bi-layers",
        sub: [
          // {
          //   heading: "Daftar Produk",
          //   route: "/superadmin/daftar-product",
          // },
          // {
          //   heading: "Data Produk",
          //   route: "/superadmin/data-product",
          // },
          // {
          //   heading: "Card Mapping",
          //   route: "/superadmin/card-mapping",
          // },
          {
            heading: "Card Desain",
            route: "/superadmin/card-design",
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
