const DocMenuConfig = [
  // {
  //   pages: [
  //     {
  //       heading: "dashboard",
  //       route: "/dashboard",
  //       svgIcon: "/media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },

  {
    pages: [
      {
        heading: "Dashboard",
        route: "/superadmin/dashboard",
        svgIcon: "/media/icons/superadmin/dashboard.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Sekolah",
        route: "/superadmin/sekolah",
        svgIcon: "/media/icons/superadmin/client.svg",
        fontIcon: "bi-layers",
      },
    ],
  },

];

export default DocMenuConfig;
