const DocMenuConfig = [
  // {
  //   pages: [
  //     {
  //       heading: "dashboard",
  //       route: "/dashboard",
  //       svgIcon: "/media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },

  {
    pages: [
      {
        heading: "Dashboard",
        route: "/superadmin/dashboard",
        svgIcon: "/media/icons/superadmin/dashboard.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Notifikasi",
        route: "/superadmin/notification",
        svgIcon: "/media/icons/superadmin/Notification.svg",
        fontIcon: "bi-layers",
      },
      {
        sectionTitle: "Bank",
        svgIcon: "/media/icons/superadmin/bank.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Bank Pusat",
            route: "/superadmin/bank-center",
          },
          {
            heading: "Area",
            route: "/superadmin/area",
          },
          {
            heading: "Cabang",
            route: "/superadmin/branch",
          },
          // {
          //   heading: "Funding",
          //   route: "/superadmin/funding",
          // },
        ],
      },
      // {
      //   heading: "Sekolah",
      //   route: "/superadmin/sekolah",
      //   svgIcon: "/media/icons/superadmin/client.svg",
      //   fontIcon: "bi-layers",
      // },
      {
        sectionTitle: "Sekolah",
        svgIcon: "/media/icons/superadmin/client.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Sekolah",
            route: "/superadmin/sekolah",
          },
          {
            heading: "Siswa",
            route: "/superadmin/siswa",
          },
          {
            heading: "History Daftar",
            route: "/superadmin/siswa/history",
          },

          {
            heading: "Monitoring",
            route: "/superadmin/sekolah/monitoring",
          },
          {
            heading: "Management Proses",
            route: "/superadmin/sekolah/management-proses",
          },
        ],
      },
      {
        sectionTitle: "Sales & Marketing",
        svgIcon: "/media/icons/superadmin/implementator.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Marketing",
            route: "/superadmin/sales/marketing",
          },
        ],
      },
      {
        sectionTitle: "Legal",
        svgIcon: "/media/icons/superadmin/legal.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Legalitas",
            route: "/superadmin/legal/legalitas",
          },
        ],
      },
      {
        sectionTitle: "Finance",
        svgIcon: "/media/icons/superadmin/finance.svg",
        fontIcon: "bi-layers",
        sub: [
          // {
          //   heading: "Finance",
          //   route: "/superadmin/finance",
          // },
          {
            heading: "Invoice",
            route: "/superadmin/invoices",
          },
          // {
          //   heading: "Report",
          //   route: "/superadmin/history-pembayaran-manual",
          // },
        ],
      },
    ],
  },

];

export default DocMenuConfig;
