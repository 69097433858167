
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  onBeforeMount,
  onBeforeUnmount,
} from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import MenuRoleSuperadmin from "@/core/config/MenuRoleSuperadmin";
import MenuRoleMarketing from "@/core/config/MenuRoleMarketing";
import MenuRoleMarketingLeader from "@/core/config/MenuRoleMarketingLeader";
import MenuRoleSales from "@/core/config/MenuRoleSales";
import MenuRoleAdminCard from "@/core/config/MenuRoleAdminCard";
import MenuRoleImplementator from "@/core/config/MenuRoleImplementator";
import MenuRoleLegal from "@/core/config/MenuRoleLegal";
import MenuRoleSekolah2 from "@/core/config/MenuRoleSekolah2";
import MenuRoleSekolah from "@/core/config/MenuRoleSekolah";
import MenuRoleFinance from "@/core/config/MenuRoleFinance";
import MenuAfterSales from "@/core/config/MenuAfterSales";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { before } from "lodash";

interface items {
  numbernotif: any;
  selfMaping: boolean;
  menu: any[];
}

export default defineComponent({
  name: "kt-menu",
  components: {},
  beforeCreate() {
    this.getSelfMapping;
    console.log("kaaa");
  },
  onBeforeMount() {
    var roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
    var role = "";
    if (roleuser == "ADMIN_LEGAL") {
      role = "legal";
    } else if (roleuser == "ADMIN_SCHOOL") {
      role = "sekolah";
    } else if (roleuser === "SALES_LEADER" || roleuser === "SALES_AREA") {
      role = "sales";
    } else if (roleuser === "SUPER_ADMIN") {
      role = "";
    } else {
      return;
    }

    if (role == "ADMIN_SCHOOL") {
    }
    ApiService.getWithoutSlug(
      "crmv2/main_notification/notification/office/all?" +
        `&type=${role}` +
        "&status=wait" +
        "&sort=createTime&dir=-1"
    )
      .then(({ data }) => {
        localStorage.setItem("badgeNotiflegal", data.totalElements);
      })
      .catch((err) => {
        localStorage.setItem("badgeNotiflegal", "0");
      });
    // undefined, state data belum di inisiasi oleh vue
  },
  setup() {
    const { t, te } = useI18n();
    const store = useStore();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const role = JSON.parse(localStorage.getItem("user_account")!).role;
    const badge = JSON.parse(localStorage.getItem("badgeNotiflegal")!);
    const selfMaping = JSON.parse(localStorage.getItem("selfMapping")!);
    const items = reactive<items>({
      numbernotif: badge,
      selfMaping: selfMaping,
      menu: [],
    });

    async function getSelfMapping() {
      const schoolId = JSON.parse(
        localStorage.getItem("user_account")!
      ).schoolId;
      ApiService.getWithoutSlug(
        "crmv2/main_school/admin_school/school/configuration/card/" + schoolId
      )
        .then((res) => {
          var cek = MenuRoleSekolah[0].pages;
          var b = [];

          console.log("after", MenuRoleSekolah, res.data.selfMapping);
          localStorage.setItem("selfMapping", res.data.selfMapping);

          items.selfMaping = res.data.selfMapping;
        })
        .catch((err) => {
          items.selfMaping = JSON.parse(localStorage.getItem("selfMapping")!);
        })
        .finally(() => {
          items.menu = getMenus(items.selfMaping);
          console.log(items.selfMaping, "items.selfMaping");
        });
      // items.selfMaping = JSON.parse(localStorage.getItem("selfMapping")!);
      console.log(items.selfMaping, "sfdee");
      // if (selfMaping == false) {
      //   MenuRoleSekolah[0].pages[4].sectionTitle = "";
      //   MenuRoleSekolah[0].pages[4].svgIcon = "";
      //   MenuRoleSekolah[0].pages[4].fontIcon = "";
      //   MenuRoleSekolah[0].pages[4].sub = [];
      //   MenuRoleSekolah[0].pages[3].sub = [
      //     {
      //       heading: "Card Desain",
      //       route: "/sekolah/card-design",
      //     },
      //   ];
      //   // MenuRoleSekolah
      // }
      // console.log(MenuRoleSekolah[0].pages);
    }

    async function getNumberNotif() {
      // items.numbernotif = 15
      var roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
      var role = "";
      if (roleuser == "ADMIN_LEGAL") {
        role = "legal";
      } else if (roleuser == "ADMIN_SCHOOL") {
        role = "sekolah";
      } else if (roleuser == "ADMIN_FINANCE") {
        role = "finance";
      } else if (roleuser === "SALES_LEADER" || roleuser === "SALES_AREA") {
        role = "sales";
      } else if (roleuser === "SUPER_ADMIN") {
        role = "";
      } else {
        return;
      }
      ApiService.setHeader();
      ApiService.getWithoutSlug(
        "crmv2/main_notification/notification/office/all?" +
          `&type=${role}` +
          "&status=wait" +
          "&sort=createTime&dir=-1"
      )
        .then(({ data }) => {
          localStorage.setItem("badgeNotiflegal", data.totalElements);
          items.numbernotif = data.totalElements;
        })
        .catch((err) => {
          localStorage.setItem("badgeNotiflegal", "0");
        });
    }

    function getMenus(selfMaping = false) {
      var roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
      // SET MENU BASES ON ROLES
      let menu: any[] = [];
      if (roleuser === "SUPER_ADMIN") menu = MenuRoleSuperadmin;
      // if (roleuser === "MARKETING_LEADER") menu = MenuRoleMarketingLeader;
      // if (roleuser === "MARKETING_MEMBER") menu = MenuRoleMarketing;
      if (roleuser === "MARKETING_LEADER" || roleuser === "MARKETING_MEMBER")
        menu = MenuRoleMarketingLeader;
      if (roleuser === "SALES_LEADER" || roleuser === "SALES_AREA")
        menu = MenuRoleSales;
      if (roleuser === "CS_LEADER" || roleuser === "CS_MEMBER")
        menu = MenuAfterSales;
      if (roleuser === "ADMIN_CARD") menu = MenuRoleAdminCard;
      if (roleuser === "ADMIN_LEGAL") menu = MenuRoleLegal;
      if (
        roleuser === "IMPLEMENTATOR_LEADER" ||
        roleuser === "IMPLEMENTATOR_MEMBER"
      )
        menu = MenuRoleImplementator;
      if (roleuser === "ADMIN_SCHOOL") {
        if (selfMaping == true) menu = MenuRoleSekolah;
        else menu = MenuRoleSekolah2;
      }
      if (roleuser === "ADMIN_FINANCE") menu = MenuRoleFinance;
      return menu;
    }

    onMounted(() => {
      getNumberNotif();
      getSelfMapping();
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();

      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MenuRoleSuperadmin,
      MenuRoleMarketing,
      MenuRoleSales,
      MenuRoleAdminCard,
      MenuRoleImplementator,
      MenuRoleSekolah,
      MenuRoleSekolah2,
      MenuRoleLegal,
      MenuRoleFinance,
      MenuAfterSales,
      items,
      getNumberNotif,
      getSelfMapping,
      getMenus,
      asideMenuIcons,
      version,
      translate,
      role,
    };
  },
});
