const DocMenuConfig = [
  // {
  //   pages: [
  //     {
  //       heading: "dashboard",
  //       route: "/dashboard",
  //       svgIcon: "/media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },

  {
    pages: [
      {
        heading: "Dashboard",
        route: "/superadmin/dashboard",
        svgIcon: "/media/icons/superadmin/dashboard.svg",
        fontIcon: "bi-layers",
      },
      {
        sectionTitle: "Sekolah",
        svgIcon: "/media/icons/superadmin/client.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Sekolah",
            route: "/superadmin/sekolah",
          },
          // {
          //   heading: "Siswa",
          //   route: "/superadmin/siswa",
          // },
          // {
          //   heading: "History Daftar",
          //   route: "/superadmin/siswa/history",
          // },

          // {
          //   heading: "Monitoring",
          //   route: "/superadmin/sekolah/monitoring",
          // },
          // {
          //   heading: "Management Proses",
          //   route: "/superadmin/sekolah/management-proses",
          // },
        ],
      },
      {
        sectionTitle: "Produk",
        svgIcon: "/media/icons/superadmin/dashboard.svg",
        fontIcon: "bi-layers",
        sub: [
          // {
          //   heading: "Daftar Produk",
          //   route: "/superadmin/daftar-product",
          // },
          // {
          //   heading: "Data Produk",
          //   route: "/superadmin/data-product",
          // },
          {
            heading: "Card Mapping",
            route: "/superadmin/card-mapping",
          },
          {
            heading: "Card Desain",
            route: "/superadmin/card-design",
          },
        ],
      },

      {
        sectionTitle: "Card Management",
        svgIcon: "/media/icons/superadmin/mapping.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Data Merchant",
            route: "/superadmin/data-merchant",
          },
          {
            heading: "Daftar Pembelian",
            route: "/superadmin/data-pembelian/all",
          },
          // {
          //   heading: "Data Produk",
          //   route: "/superadmin/data-product",
          // },
          {
            heading: "Data Mapping Kartu",
            route: "/superadmin/data-mapping",
          },
          // {
          //   heading: "Keranjang",
          //   route: "/superadmin/card-design",
          // },
        ],
      },


    ],
  },

];

export default DocMenuConfig;
