const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/sekolah/dashboard",
        svgIcon: "/media/icons/superadmin/dashboard.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Notifikasi",
        route: "/sekolah/notification",
        svgIcon: "/media/icons/superadmin/Notification.svg",
        fontIcon: "bi-layers",
      },
      {
        sectionTitle: "Sekolah",
        svgIcon: "/media/icons/superadmin/client.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Sekolah",
            route: "/sekolah/sekolah",
          },
          {
            heading: "Siswa",
            route: "/sekolah/siswa",
          },
          {
            heading: "History Daftar",
            route: "/sekolah/siswa/history",
          },
        ],
      },
      // {
      //   sectionTitle: "Legal",
      //   svgIcon: "/media/icons/superadmin/legal.svg",
      //   fontIcon: "bi-layers",
      //   sub: [
      //     {
      //       heading: "Legalitas",
      //       route: "/sekolah/legal/legalitas",
      //     },
      //     // {
      //     //   heading: "Admin Legal",
      //     //   route: "/superadmin/legal/admin-legal",
      //     // },
      //   ],
      // },
      {
        sectionTitle: "Finance",
        svgIcon: "/media/icons/superadmin/finance.svg",
        fontIcon: "bi-layers",
        sub: [
          // {
          //   heading: "Finance",
          //   route: "/superadmin/finance",
          // },
          {
            heading: "Invoice",
            route: "/sekolah/invoices"
          },
          // {
          //   heading: "Persetujuan Finance",
          //   route: "/superadmin/konfirmasi-invoice",
          // },
          {
            sectionTitle: "Log VA",
            sub: [
              {
                heading: "Log Create VA",
                route: "/sekolah/log-create-va",
              },
              {
                heading: "Log Callback VA",
                route: "/sekolah/log-callback-va",
              },
            ],
          },
        ],
      },
      {
        sectionTitle: "Produk",
        svgIcon: "/media/icons/superadmin/kartu.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Card Desain",
            route: "/sekolah/card-design",
          },

        ],
      },
      // {
      //   sectionTitle: "Order Produk",
      //   svgIcon: "/media/icons/superadmin/mapping.svg",
      //   fontIcon: "bi-layers",
      //   sub: [
      //     {
      //       heading: "Master Produk",
      //       route: "/superadmin/master-produk",
      //     },
      //     {
      //       heading: "Data Produk",
      //       route: "/superadmin/daftar-produk",
      //     },
      //   ],
      // },

    ],
  },

];

export default DocMenuConfig;
