const DocMenuConfig = [
    // {
    //   pages: [
    //     {
    //       heading: "dashboard",
    //       route: "/dashboard",
    //       svgIcon: "/media/icons/duotune/art/art002.svg",
    //       fontIcon: "bi-app-indicator",
    //     },
    //   ],
    // },

    {
        pages: [
            {
                heading: "Dashboard",
                route: "/superadmin/dashboard",
                svgIcon: "/media/icons/superadmin/dashboard.svg",
                fontIcon: "bi-layers",
            },
            {
                heading: "Notifikasi",
                route: "/superadmin/notification",
                svgIcon: "/media/icons/superadmin/Notification.svg",
                fontIcon: "bi-layers",
            },

            // {
            //     sectionTitle: "Sekolah",
            //     svgIcon: "/media/icons/superadmin/client.svg",
            //     fontIcon: "bi-layers",
            //     sub: [
            //         {
            //             heading: "Sekolah",
            //             route: "/superadmin/sekolah",
            //         },
            //         {
            //             heading: "Siswa",
            //             route: "/superadmin/siswa",
            //         },
            //         {
            //             heading: "History Daftar",
            //             route: "/superadmin/siswa/history",
            //         },
            //     ],
            // },
            // {
            //   sectionTitle: "Sales & Marketing",
            //   svgIcon: "/media/icons/superadmin/implementator.svg",
            //   fontIcon: "bi-archive",
            //   sub: [
            //     {
            //       heading: "Marketing",
            //       route: "/superadmin/sales/marketing",
            //     },
            //     {
            //       heading: "Sales Area",
            //       route: "/superadmin/sales/sales-area",
            //     },
            //     {
            //       heading: "Implementator",
            //       route: "/superadmin/sales/implementator",
            //     },
            //     {
            //       heading: "Cs & After Sales",
            //       route: "/superadmin/sales/cs",
            //     },
            //   ],
            // },
            {
                sectionTitle: "Legal",
                svgIcon: "/media/icons/superadmin/legal.svg",
                fontIcon: "bi-layers",
                sub: [
                    {
                        heading: "Legalitas",
                        route: "/superadmin/legal/legalitas",
                    },
                    {
                        heading: "Konfirmasi Legal",
                        route: "/superadmin/legal/konfirmasiLegal",
                    }, {
                        heading: "Data NPWP",
                        route: "/superadmin/legal/data-npwp",
                    },
                    // {
                    //   heading: "Admin Legal",
                    //   route: "/superadmin/legal/admin-legal",
                    // },
                ],
            },
            {
                sectionTitle: "Finance",
                svgIcon: "/media/icons/superadmin/finance.svg",
                fontIcon: "bi-layers",
                sub: [
                    {
                        heading: "Invoice",
                        route: "/superadmin/invoices",
                    },
                    {
                        heading: "Persetujuan Finance",
                        route: "/superadmin/konfirmasi-invoice",
                    },
                    {
                        heading: "Report",
                        route: "/superadmin/history-pembayaran-manual",
                    },
                    {
                        heading: "Setting Invoice",
                        route: "/superadmin/setting-invoices",
                    },
                    // {
                    //     heading: "Setting Invoice Product",
                    //     route: "/superadmin/setting-invoices-product",
                    //   },
                    {
                        sectionTitle: "Log VA",
                        sub: [
                            {
                                heading: "Log Create VA",
                                route: "/superadmin/log-create-va",
                            },
                            {
                                heading: "Log Callback VA",
                                route: "/superadmin/log-callback-va",
                            },
                        ],
                    },
                ],
            },


            // {
            //   sectionTitle: "Generate Kartu",
            //   route: "/generatekartu",
            //   svgIcon: "/media/icons/duotune/finance/fin002.svg",
            //   fontIcon: "bi-app-indicator",
            //   sub: [
            //     {
            //       heading: "Dashboard Admin Kartu",
            //       route: "/apps/generatekartu/userGenerateKartu",
            //     },
            //     {
            //       heading: "Buat Desain",
            //       route: "/apps/generatekartu/buatDesain",
            //     },
            //   ],
            // },
        ],
    },
];

export default DocMenuConfig;
