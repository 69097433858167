const DocMenuConfig = [
  // {
  //   pages: [
  //     {
  //       heading: "dashboard",
  //       route: "/dashboard",
  //       svgIcon: "/media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },

  {
    pages: [
      {
        heading: "Dashboard",
        route: "/superadmin/dashboard",
        svgIcon: "/media/icons/superadmin/dashboard.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Bank",
        route: "/superadmin-bank",
        svgIcon: "/media/icons/superadmin/bank.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Client",
        route: "/superadmin-client",
        svgIcon: "/media/icons/superadmin/client.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Implementator",
        route: "/superadmin-implementator",
        svgIcon: "/media/icons/superadmin/implementator.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Legal",
        route: "/superadmin-legal",
        svgIcon: "/media/icons/superadmin/legal.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Finance",
        route: "/superadmin-finance",
        svgIcon: "/media/icons/superadmin/finance.svg",
        fontIcon: "bi-layers",
      },
      // {
      //   heading: "Kartu",
      //   route: "/superadmin-kartu",
      //   svgIcon: "/media/icons/superadmin/kartu.svg",
      //   fontIcon: "bi-layers",
      // },
      {
        sectionTitle: "Generate Kartu",
        route: "/generatekartu",
        svgIcon: "/media/icons/duotune/finance/fin002.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Dashboard Admin Kartu",
            route: "/apps/generatekartu/userGenerateKartu",
            // sub: [
            //   {
            //     heading: "Buat Desain",
            //     route: "/apps/generatekartu/userGenerateKartu",
            //   },
            // ],
          },
          {
            heading: "Buat Desain",
            route: "/apps/generatekartu/buatDesain",
          },
        ],
      },
    ],
  },

];

export default DocMenuConfig;
