const DocMenuConfig = [
  // {
  //   pages: [
  //     {
  //       heading: "dashboard",
  //       route: "/dashboard",
  //       svgIcon: "/media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },

  {
    pages: [
      {
        heading: "Dashboard",
        route: "/superadmin/dashboard",
        svgIcon: "/media/icons/superadmin/dashboard.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Notifikasi",
        route: "/superadmin/notification",
        svgIcon: "/media/icons/superadmin/Notification.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "User",
        route: "/superadmin/user",
        svgIcon: "/media/icons/superadmin/implementator.svg",
        fontIcon: "bi-layers",
      },
      {
        sectionTitle: "Bank",
        svgIcon: "/media/icons/superadmin/bank.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Bank Pusat",
            route: "/superadmin/bank-center",
          },
          {
            heading: "Area",
            route: "/superadmin/area",
          },
          {
            heading: "Cabang",
            route: "/superadmin/branch",
          },
          // {
          //   heading: "Funding",
          //   route: "/superadmin/funding",
          // },
        ],
      },
      {
        sectionTitle: "Sekolah",
        svgIcon: "/media/icons/superadmin/client.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Sekolah",
            route: "/superadmin/sekolah",
          },
          {
            heading: "Siswa",
            route: "/superadmin/siswa",
          },
          {
            heading: "History Daftar",
            route: "/superadmin/siswa/history",
          },

          {
            heading: "Monitoring",
            route: "/superadmin/sekolah/monitoring",
          },
          {
            heading: "Management Proses",
            route: "/superadmin/sekolah/management-proses",
          },
        ],
      },
      {
        sectionTitle: "Sales & Marketing",
        svgIcon: "/media/icons/superadmin/implementator.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Marketing",
            route: "/superadmin/sales/marketing",
          },
          {
            heading: "Sales Area",
            route: "/superadmin/sales/sales-area",
          },
          {
            heading: "Implementator",
            route: "/superadmin/sales/implementator",
          },
          {
            heading: "Cs & After Sales",
            route: "/superadmin/sales/cs",
          },
        ],
      },
      {
        sectionTitle: "Legal",
        svgIcon: "/media/icons/superadmin/legal.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Legalitas",
            route: "/superadmin/legal/legalitas",
          },
          {
            heading: "Konfirmasi Legal",
            route: "/superadmin/legal/konfirmasiLegal",
          },
          {
            heading: "Data NPWP",
            route: "/superadmin/legal/data-npwp",
          },
          // {
          //   heading: "Admin Legal",
          //   route: "/superadmin/legal/admin-legal",
          // },
        ],
      },
      {
        sectionTitle: "Finance",
        svgIcon: "/media/icons/superadmin/finance.svg",
        fontIcon: "bi-layers",
        sub: [
          // {
          //   heading: "Finance",
          //   route: "/superadmin/finance",
          // },
          {
            heading: "Invoice",
            route: "/superadmin/invoices",
          },
          {
            heading: "Persetujuan Finance",
            route: "/superadmin/konfirmasi-invoice",
          },
          {
            heading: "Report",
            route: "/superadmin/history-pembayaran-manual",
          },
          {
            heading: "Setting Invoice",
            route: "/superadmin/setting-invoices",
          },
          {
            heading: "Setting Invoice Product",
            route: "/superadmin/setting-invoices-product",
          },
          {
            sectionTitle: "Log VA",
            sub: [
              {
                heading: "Log Create VA",
                route: "/superadmin/log-create-va",
              },
              {
                heading: "Log Callback VA",
                route: "/superadmin/log-callback-va",
              },
            ],
          },
        ],
      },
      {
        sectionTitle: "Produk",
        svgIcon: "/media/icons/superadmin/kartu.svg",
        fontIcon: "bi-layers",
        sub: [
          // {
          //   heading: "Daftar Produk",
          //   route: "/superadmin/daftar-product",
          // },
          // {
          //   heading: "Data Produk",
          //   route: "/superadmin/data-product",
          // },
          {
            heading: "Card Mapping",
            route: "/superadmin/card-mapping",
          },
          {
            heading: "Card Desain",
            route: "/superadmin/card-design",
          },

        ],
      },
      // {
      //   sectionTitle: "Card Management",
      //   svgIcon: "/media/icons/superadmin/mapping.svg",
      //   fontIcon: "bi-layers",
      //   sub: [
      //     {
      //       heading: "Data Merchant",
      //       route: "/superadmin/data-merchant",
      //     },
      //     {
      //       heading: "Daftar Pembelian",
      //       route: "/superadmin/data-pembelian/all",
      //     },
      //     // {
      //     //   heading: "Data Produk",
      //     //   route: "/superadmin/data-product",
      //     // },
      //     {
      //       heading: "Data Mapping Kartu",
      //       route: "/superadmin/data-mapping",
      //     },
      //   ],
      // },
      {
        sectionTitle: "Card Management",
        svgIcon: "/media/icons/superadmin/mapping.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Data Merchant",
            route: "/superadmin/data-merchant",
          },
          {
            heading: "Daftar Pembelian",
            route: "/superadmin/data-pembelian/all",
          },
          {
            heading: "Data Mapping Kartu",
            route: "/superadmin/data-mapping",
          },
        ],
      },
      // {
      //   sectionTitle: "Order Produk",
      //   svgIcon: "/media/icons/superadmin/mapping.svg",
      //   fontIcon: "bi-layers",
      //   sub: [
      //     {
      //       heading: "Master Produk",
      //       route: "/superadmin/master-produk",
      //     },
      //     {
      //       heading: "Data Produk",
      //       route: "/superadmin/daftar-produk",
      //     },
      //     {
      //       heading: "Data Pesanan",
      //       route: "/superadmin/data-pesanan",
      //     },

      //     {
      //       heading: "Keranjang",
      //       route: "/superadmin/cart-product",
      //     },
      //   ],
      // },
      // {
      //   sectionTitle: "Card Management",
      //   svgIcon: "/media/icons/superadmin/mapping.svg",
      //   fontIcon: "bi-layers",
      //   sub: [
      //     {
      //       heading: "Data Merchant",
      //       route: "/superadmin/data-merchant",
      //     },
      //     {
      //       heading: "Daftar Pembelian",
      //       route: "/superadmin/data-pembelian/all",
      //     },
      //     // {
      //     //   heading: "Data Produk",
      //     //   route: "/superadmin/data-product",
      //     // },
      //     {
      //       heading: "Data Mapping Kartu",
      //       route: "/superadmin/data-mapping",
      //     },
      //   ],
      // },
      // {
      //   sectionTitle: "Order Produk",
      //   svgIcon: "/media/icons/superadmin/mapping.svg",
      //   fontIcon: "bi-layers",
      //   sub: [
      //     {
      //       heading: "Master Produk",
      //       route: "/superadmin/master-produk",
      //     },
      //     {
      //       heading: "Data Produk",
      //       route: "/superadmin/daftar-produk",
      //     },

      //   ],
      // },

    ],
  },
];

export default DocMenuConfig;
