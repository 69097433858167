
const badge = JSON.parse(localStorage.getItem("badgeNotiflegal")!);

const DocMenuConfig = [

  {
    pages: [
      {
        heading: "Dashboard",
        route: "/superadmin/dashboard",
        svgIcon: "/media/icons/superadmin/dashboard.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Notifikasi",
        route: "/superadmin/notification",
        svgIcon: "/media/icons/superadmin/Notification.svg",
        fontIcon: "bi-layers",
        notif: badge
      },
      {
        sectionTitle: "Sekolah",
        svgIcon: "/media/icons/superadmin/client.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Sekolah",
            route: "/superadmin/sekolah",
          },
        ],
      },
      {
        sectionTitle: "Legal",
        svgIcon: "/media/icons/superadmin/legal.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "Legalitas",
            route: "/superadmin/legal/legalitas",
          },
          {
            heading: "Konfirmasi Legal",
            route: "/superadmin/legal/konfirmasiLegal",
          }, {
            heading: "Data NPWP",
            route: "/superadmin/legal/data-npwp",
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
